<template>
  <div>
    <label for="text">
      State
      <span class="star" v-if="displayRequired">*</span>
    </label>
    <el-select
      placeholder="Select State"
      default-first-option
      v-model="state.state"
      filterable
      :filter-method="filterStates"
      @change="clearCity"
      
      clearable
      :disabled="disableField"
    >
      <el-option
        v-for="state in filteredStates"
        :key="state._id"
        :label="state.name"
        :value="state.name"
      />
    </el-select>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("help", ["getAllStates", "getAllCities"]),

    getErrorKey() {
      return `state`;
    },
  },
  props:{    
    stateData: { required: true },
    validataionErrors: { required: false },
    errorKey: { required: false },
    displayRequired: { default: true },
    disableField: { type: Boolean, default: false },
  },
  data() {
    return {
      filteredStates: [],
      includeCurrentPayor: true,
      state: "",
    };
  },
  async mounted() {
    await this.$store.dispatch("help/fetchAllStates");
    this.setAllStates();
    this.state = this.stateData;
  },
  methods: {
    clearCity() {
      this.state.city = "";
    },
    setAllStates() {
      this.filteredStates = [...this.getAllStates];
    },
    filterStates(filterValue) {
      if (filterValue && filterValue.length) {
        this.filteredStates = [...this.getAllStates].filter(
          (option) =>
            option.name.toLowerCase().indexOf(filterValue.toLowerCase()) == 0
        );
      } else {
        this.setAllStates();
      }
    },
  },
  watch: {
    stateData: {
      immediate: true,
      deep: true,
      handler: function () {
        this.state = this.stateData;
      },
    },
  },
};
</script>

<style scoped>
.err {
  margin-top: 2px !important;
  color: #eb5757;
  font-size: 12px;
  min-height: 20px;
  white-space: nowrap;
  margin-bottom: -30px;
  line-height: 20px;
  font-weight: 500;
  padding-left: 2px;
}
</style>
